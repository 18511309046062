import { render, staticRenderFns } from "./Doc18.vue?vue&type=template&id=fcf2e660&scoped=true&"
import script from "./Doc18.vue?vue&type=script&lang=js&"
export * from "./Doc18.vue?vue&type=script&lang=js&"
import style0 from "./Doc18.vue?vue&type=style&index=0&id=fcf2e660&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fcf2e660",
  null
  
)

export default component.exports
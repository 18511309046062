<template>
  <section class="dtc-main-section mt-4" id="">
    <header
      class="dtc-title"
      style="font-size: 20px; display: grid; font-family:DFKai-sb;"
    >
      <div style="text-align:left">附件4-4</div>
      <div>衛生福利部偏鄉護理菁英計畫</div>
    </header>
    <header
      style="font-size: 20px; display: grid; place-items: center;font-family:DFKai-sb;border-bottom: 1px solid black;height:50px"
    >
      <div style="border:0px">
        {{ school }}學校{{ personInfo.byear }}學年度{{
          personInfo.term == 1 ? "上" : "下"
        }}學期護理公費生個別輔導紀錄表
      </div>
    </header>

    <main class="grid-4 pb-3">
      <div style="border-top:0px">姓名</div>
      <div style="border-top:0px">{{ name }}</div>
      <div style="border-top:0px;">年級/班級/學號</div>
      <div style="border-top:0px;border-right:0px">{{ classData }}</div>

      <div>
        性別
      </div>
      <div>
        <span v-if="gender" class="icon">☑</span>
        <span v-if="!gender" class="icon">☐</span>
        男性

        <span v-if="!gender" class="icon">☑</span>
        <span v-if="gender" class="icon">☐</span>
        女性
      </div>

      <div>電話</div>
      <div style="border-right:0px">{{ telphone }}</div>

      <div style="grid-column: 1/3">輔導次別</div>

      <div style="grid-column: 3/5;border-right:0px">
        <span v-if="checkTime == '1'" class="icon">☑</span>
        <span v-if="checkTime != '1'" class="icon">☐</span>
        第一次
        <span v-if="checkTime == '2'" class="icon">☑</span>
        <span v-if="checkTime != '2'" class="icon">☐</span>
        第二次
        <span v-if="checkTime == '3'" class="icon">☑</span>
        <span v-if="checkTime != '3'" class="icon">☐</span>
        第三次
        <span v-if="checkTime == '4'" class="icon">☑</span>
        <span v-if="checkTime != '4'" class="icon">☐</span>
        第四次
      </div>

      <div>輔導日期</div>
      <div>{{ tutorDate }}</div>
      <div>輔導形式</div>
      <div style=";border-right:0px">面談地點: {{ tutorPlace }}</div>

      <div style="grid-column: 1/-1;text-align:left;padding-left:30px;">
        輔 導 內 容
      </div>
    </main>

    <main class="grid-2">
      <div class="grid-2-left" style="border-top:0px;padding-top:5px;">
        一、輔導原因:
      </div>
      <div
        class="grid-2-right"
        style="border-top:0px;border-right:0px;padding-top:5px"
      >
        <span v-if="qa01[0].value" class="icon">☑</span>
        <span v-if="!qa01[0].value" class="icon">☐</span>
        大一新生入學輔導
        <span v-if="qa01[1].value" class="icon">☑</span>
        <span v-if="!qa01[1].value" class="icon">☐</span>
        選課、一般課程輔導
        <span v-if="qa01[2].value" class="icon">☑</span>
        <span v-if="!qa01[2].value" class="icon">☐</span>
        生活輔導(生 涯規劃、轉系諮詢、人際關係、經濟、家庭、感情、情緒等
        <span v-if="qa01[3].value" class="icon">☑</span>
        <span v-if="!qa01[3].value" class="icon">☐</span>
        學習狀況不佳 (缺曠、上課不專注、成績不佳等)
        <span v-if="qa01[4].value" class="icon">☑</span>
        <span v-if="!qa01[4].value" class="icon">☐</span>
        期中預警
        <span v-if="qa01[5].value" class="icon">☑</span>
        <span v-if="!qa01[5].value" class="icon">☐</span>
        上學期二分之一以上學分不及格
        <span v-if="qa01[6].value" class="icon">☑</span>
        <span v-if="!qa01[6].value" class="icon">☐</span>
        一般訪談
        <span v-if="qa01[7].value" class="icon">☑</span>
        <span v-if="!qa01[7].value" class="icon">☐</span>
        緊急事件處理，事件 : {{ qa01Event }}
        <span v-if="qa01[8].value" class="icon">☑</span>
        <span v-if="!qa01[8].value" class="icon">☐</span>
        其他 : {{ qa01Other }}
      </div>
      <div class="grid-2-left" style="border:0px">二、學生狀況評估:</div>
      <div class="grid-2-right" style="border:0px"></div>
      <div class="grid-2-left" style="border:0px">1.家庭狀況</div>
      <div class="grid-2-right" style="border:0px">
        <span v-if="qa021[0].value" class="icon">☑</span>
        <span v-if="!qa021[0].value" class="icon">☐</span>
        雙親家庭
        <span v-if="qa021[1].value" class="icon">☑</span>
        <span v-if="!qa021[1].value" class="icon">☐</span>
        單親家庭
        <span v-if="qa021[2].value" class="icon">☑</span>
        <span v-if="!qa021[2].value" class="icon">☐</span>
        隔代教養家庭
        <span v-if="qa021[3].value" class="icon">☑</span>
        <span v-if="!qa021[3].value" class="icon">☐</span>
        失親
        <span v-if="qa021[4].value" class="icon">☑</span>
        <span v-if="!qa021[4].value" class="icon">☐</span>
        依親
        <span v-if="qa021[5].value" class="icon">☑</span>
        <span v-if="!qa021[5].value" class="icon">☐</span>
        自己外居
        <span v-if="qa021[6].value" class="icon">☑</span>
        <span v-if="!qa021[6].value" class="icon">☐</span>
        僅與兄弟姊妹同住
        <span v-if="qa021[7].value" class="icon">☑</span>
        <span v-if="!qa021[7].value" class="icon">☐</span>
        其他 : {{ qa021Other }}
      </div>

      <div class="grid-2-left" style="border:0px">2.家庭經濟</div>
      <div class="grid-2-right" style="border:0px">
        <span v-if="qa022[0].value" class="icon">☑</span>
        <span v-if="!qa022[0].value" class="icon">☐</span>
        家庭經濟富裕
        <span v-if="qa022[1].value" class="icon">☑</span>
        <span v-if="!qa022[1].value" class="icon">☐</span>
        家庭經濟小康
        <span v-if="qa022[2].value" class="icon">☑</span>
        <span v-if="!qa022[2].value" class="icon">☐</span>
        家庭經濟普通
        <span v-if="qa022[3].value" class="icon">☑</span>
        <span v-if="!qa022[3].value" class="icon">☐</span>
        家庭經濟不佳
        <span v-if="qa022[4].value" class="icon">☑</span>
        <span v-if="!qa022[4].value" class="icon">☐</span>
        其他 : {{ qa022Other }}
      </div>

      <div class="grid-2-left" style="border:0px">3.家庭互動</div>
      <div class="grid-2-right" style="border:0px">
        <span v-if="qa023[0].value" class="icon">☑</span>
        <span v-if="!qa023[0].value" class="icon">☐</span>
        家庭互動良好
        <span v-if="qa023[1].value" class="icon">☑</span>
        <span v-if="!qa023[1].value" class="icon">☐</span>
        家庭互動尚可
        <span v-if="qa023[2].value" class="icon">☑</span>
        <span v-if="!qa023[2].value" class="icon">☐</span>
        家庭互動不佳
        <span v-if="qa023[3].value" class="icon">☑</span>
        <span v-if="!qa023[3].value" class="icon">☐</span>
        其他 : {{ qa023Other }}
      </div>

      <div class="grid-2-left" style="border:0px">4.學習志趣</div>
      <div class="grid-2-right" style="border:0px">
        <span v-if="qa024[0].value" class="icon">☑</span>
        <span v-if="!qa024[0].value" class="icon">☐</span>
        目前系（所）合乎自己的志趣
        <span v-if="qa024[1].value" class="icon">☑</span>
        <span v-if="!qa024[1].value" class="icon">☐</span>
        目前系（所）不合乎自己的志趣，但會完成它
        <span v-if="qa024[2].value" class="icon">☑</span>
        <span v-if="!qa024[2].value" class="icon">☐</span>
        目前系（所）不是自己的志趣，所以打算轉換系（所）
        <span v-if="qa024[3].value" class="icon">☑</span>
        <span v-if="!qa024[3].value" class="icon">☐</span>
        其他 : {{ qa024Other }}
      </div>

      <div class="grid-2-left" style="border:0px">5.學習狀況</div>
      <div class="grid-2-right" style="border:0px">
        <span v-if="qa025[0].value" class="icon">☑</span>
        <span v-if="!qa025[0].value" class="icon">☐</span>
        學習狀況良好
        <span v-if="qa025[1].value" class="icon">☑</span>
        <span v-if="!qa025[1].value" class="icon">☐</span>
        學習狀況尚可
        <span v-if="qa025[2].value" class="icon">☑</span>
        <span v-if="!qa025[2].value" class="icon">☐</span>
        學習狀況不佳
        <span v-if="qa025[3].value" class="icon">☑</span>
        <span v-if="!qa025[3].value" class="icon">☐</span>
        預警輔導未改善
        <span v-if="qa025[4].value" class="icon">☑</span>
        <span v-if="!qa025[4].value" class="icon">☐</span>
        其它 : {{ qa025Other }}
      </div>

      <div class="grid-2-left" style="border:0px">6.生涯規劃</div>
      <div class="grid-2-right" style="border:0px">
        <span v-if="qa026[0].value" class="icon">☑</span>
        <span v-if="!qa026[0].value" class="icon">☐</span>
        對未來已有計畫
        <span v-if="qa026[1].value" class="icon">☑</span>
        <span v-if="!qa026[1].value" class="icon">☐</span>
        順其自然
        <span v-if="qa026[2].value" class="icon">☑</span>
        <span v-if="!qa026[2].value" class="icon">☐</span>
        對未來感到茫然
        <span v-if="qa026[3].value" class="icon">☑</span>
        <span v-if="!qa026[3].value" class="icon">☐</span>
        其他 : {{ qa026Other }}
      </div>

      <div class="grid-2-left" style="border:0px">7.生活適應</div>
      <div class="grid-2-right" style="border:0px">
        <span v-if="qa027[0].value" class="icon">☑</span>
        <span v-if="!qa027[0].value" class="icon">☐</span>
        適應良好
        <span v-if="qa027[1].value" class="icon">☑</span>
        <span v-if="!qa027[1].value" class="icon">☐</span>
        尚可
        <span v-if="qa027[2].value" class="icon">☑</span>
        <span v-if="!qa027[2].value" class="icon">☐</span>
        適應不良，問題 : {{ qa027Remark }}
        <span v-if="qa027[3].value" class="icon">☑</span>
        <span v-if="!qa027[3].value" class="icon">☐</span>
        其他 : {{ qa027Other }}
      </div>

      <div class="grid-2-left" style="border:0px">8.人際關係</div>
      <div class="grid-2-right" style="border:0px">
        <span v-if="qa028[0].value" class="icon">☑</span>
        <span v-if="!qa028[0].value" class="icon">☐</span>
        與同儕互動佳
        <span v-if="qa028[1].value" class="icon">☑</span>
        <span v-if="!qa028[1].value" class="icon">☐</span>
        尚可
        <span v-if="qa028[2].value" class="icon">☑</span>
        <span v-if="!qa028[2].value" class="icon">☐</span>
        與同儕互動不佳，原因 : {{ qa028Remark }}
        <span v-if="qa028[3].value" class="icon">☑</span>
        <span v-if="!qa028[3].value" class="icon">☐</span>
        其他 : {{ qa028Other }}
      </div>

      <div class="grid-2-left" style="border:0px">9.身體健康</div>
      <div class="grid-2-right" style="border:0px">
        <span v-if="qa029[0].value" class="icon">☑</span>
        <span v-if="!qa029[0].value" class="icon">☐</span>
        健康良好
        <span v-if="qa029[1].value" class="icon">☑</span>
        <span v-if="!qa029[1].value" class="icon">☐</span>
        尚可
        <span v-if="qa029[2].value" class="icon">☑</span>
        <span v-if="!qa029[2].value" class="icon">☐</span>
        健康狀況不佳
        <span v-if="qa029[3].value" class="icon">☑</span>
        <span v-if="!qa029[3].value" class="icon">☐</span>
        身體疾病 : {{ qa029Health }}
        <span v-if="qa029[4].value" class="icon">☑</span>
        <span v-if="!qa029[4].value" class="icon">☐</span>
        領有身心障礙手冊
        <span v-if="qa029[5].value" class="icon">☑</span>
        <span v-if="!qa029[5].value" class="icon">☐</span>
        其他 : {{ qa029Other }}
      </div>

      <div class="grid-2-left" style="border:0px;padding-bottom:10px">
        10.心理健康
      </div>
      <div
        class="grid-2-right"
        style="border:0px;padding-bottom:10px;page-break-after:always"
      >
        <span v-if="qa0210[0].value" class="icon">☑</span>
        <span v-if="!qa0210[0].value" class="icon">☐</span>
        樂觀開朗
        <span v-if="qa0210[1].value" class="icon">☑</span>
        <span v-if="!qa0210[1].value" class="icon">☐</span>
        尚可
        <span v-if="qa0210[2].value" class="icon">☑</span>
        <span v-if="!qa0210[2].value" class="icon">☐</span>
        經常情緒低落
        <span v-if="qa0210[3].value" class="icon">☑</span>
        <span v-if="!qa0210[3].value" class="icon">☐</span>
        情緒起伏很大
        <span v-if="qa0210[4].value" class="icon">☑</span>
        <span v-if="!qa0210[4].value" class="icon">☐</span>
        精神疾病 : {{ qa0210Health }}
        <span v-if="qa0210[5].value" class="icon">☑</span>
        <span v-if="!qa0210[5].value" class="icon">☐</span>
        其他 : {{ qa0210Other }}
      </div>
    </main>

    <footer class="print-border">
      <div style="font-size:17px">
        問題原因、輔導摘要(必填):
      </div>
      <div style="font-size:17px;min-height:170px;word-wrap: break-word;">
        {{ map.Remark1 }}
      </div>
    </footer>
    <footer>
      <div style="font-size:17px;font-weight: 500;">
        處理方式、建議事項或其他資源協助(必填)：
      </div>
      <div
        style="font-size:17px;font-weight: 500;min-height:170px;word-wrap: break-word;"
      >
        {{ map.Remark2 }}
      </div>
    </footer>
    <footer class="footer-last">
      <div style="border:0px;transform: translateX(20px);">
        備註：本輔導以面談為主，每學期每名學生至少面談4次。
      </div>
      <div class="footerWord">輔導者簽名：</div>
      <div class="footerWord">導師簽名:</div>
      <div class="footerWord">
        中華民國
        <span class="footerWordTime">年</span>
        <span class="footerWordTime">月</span>
        <span class="footerWordTime">日</span>
      </div>
    </footer>
  </section>
</template>

<script>
import queryString from "query-string";
import { store } from "@/store/global.js";

export default {
  name: "totalSpend15",
  data() {
    return {
      year: 108,
      term: 1,
      school: "",
      budege: "138,373",
      map: {},
      name: "",
      classData: "",
      gender: false,
      telphone: "",
      tutor: "",
      firstTime: false,
      secondTime: false,
      thirdTime: false,
      fourthTime: false,
      checkTime: "",
      qa01: ["N", "N", "N", "N", "N", "N", "N", "N", "N"],
      qa01Other: "",
      qa01Event: "",
      qa021Other: "",
      qa021: ["N", "N", "N", "N", "N", "N", "N", "N"],
      qa021Other: "",
      qa022: ["N", "N", "N", "N", "N"],
      qa022Other: "",
      qa023: ["N", "N", "N", "N"],
      qa023Other: "",
      qa024: ["N", "N", "N", "N"],
      qa024Other: "",
      qa025: ["N", "N", "N", "N", "N"],
      qa025Other: "",
      qa026: ["N", "N", "N", "N"],
      qa026Other: "",
      qa027: ["N", "N", "N", "N"],
      qa027Other: "",
      qa027Remark: "",
      qa028: ["N", "N", "N", "N"],
      qa028Other: "",
      qa028Remark: "",
      qa029: ["N", "N", "N", "N", "N", "N"],
      qa029Other: "",
      qa029Health: "",
      qa0210: ["N", "N", "N", "N", "N", "N"],
      qa0210Other: "",
      qa0210Health: "",
      tutorDate: "",
      tutorPlace: "",
      remark1: "",
      remark2: "",
      personInfo: {},
    };
  },
  computed: {},
  methods: {
    async getData() {
      this.personInfo = JSON.parse(sessionStorage.getItem("print"));

      const url = `api/NurseRecord?$filter=SchoolCode eq '${this.personInfo.schoolcode}' and substringof('${this.personInfo.byear}' , Ryear ) and substringof('${this.personInfo.term}' , Term ) and substringof('${this.personInfo.id}' , Identifier ) and substringof('${this.personInfo.tutor}' , Tutor )`;
      try {
        const { Items } = await window.axios.get(url);
        let map = JSON.parse(JSON.stringify(Items[0]));
        // console.log("map", map);
        this.map = JSON.parse(JSON.stringify(map));

        this.name = map.StudentName;

        if (map.Grade == 1) {
          map.GradeName = "一";
        } else if (map.Grade == 2) {
          map.GradeName = "二";
        } else if (map.Grade == 3) {
          map.GradeName = "三";
        } else if (map.Grade == 4) {
          map.GradeName = "四";
        }

        this.classData = `${map.GradeName}/ ${map.Class}/ ${map.StudentId}`;
        this.gender = map.Gender == "M" ? true : false;
        this.telphone = map.Telphone;
        this.checkTime = map.Tutor;
        this.qa01 = map.Question_01.split(",");
        this.qa01 = this.qa01.map((s) => ({
          value: s == "Y" ? true : false,
        }));

        this.qa01Other = map.Question_01_Other;
        this.qa01Event = map.Question_01_Event;

        this.qa021 = map.Question_021.split(",");
        this.qa021 = this.qa021.map((s) => ({
          value: s == "Y" ? true : false,
        }));
        this.qa021Other = map.Question_021_Other;

        this.qa022 = map.Question_022.split(",");
        this.qa022 = this.qa022.map((s) => ({
          value: s == "Y" ? true : false,
        }));
        this.qa022Other = map.Question_022_Other;

        this.qa023 = map.Question_023.split(",");
        this.qa023 = this.qa023.map((s) => ({
          value: s == "Y" ? true : false,
        }));
        this.qa023Other = map.Question_023_Other;

        this.qa024 = map.Question_024.split(",");
        this.qa024 = this.qa024.map((s) => ({
          value: s == "Y" ? true : false,
        }));
        this.qa024Other = map.Question_024_Other;

        this.qa025 = map.Question_025.split(",");
        this.qa025 = this.qa025.map((s) => ({
          value: s == "Y" ? true : false,
        }));
        this.qa025Other = map.Question_025_Other;

        this.qa026 = map.Question_026.split(",");
        this.qa026 = this.qa026.map((s) => ({
          value: s == "Y" ? true : false,
        }));
        this.qa026Other = map.Question_026_Other;

        this.qa027 = map.Question_027.split(",");
        this.qa027 = this.qa027.map((s) => ({
          value: s == "Y" ? true : false,
        }));
        this.qa027Other = map.Question_027_Other;
        this.qa027Remark = map.Question_027_Remark;

        this.qa028 = map.Question_028.split(",");
        this.qa028 = this.qa028.map((s) => ({
          value: s == "Y" ? true : false,
        }));
        this.qa028Other = map.Question_028_Other;
        this.qa028Remark = map.Question_028_Remark;

        this.qa029 = map.Question_029.split(",");
        this.qa029 = this.qa029.map((s) => ({
          value: s == "Y" ? true : false,
        }));
        this.qa029Other = map.Question_029_Other;
        this.qa029Health = map.Question_029_Health;

        this.qa0210 = map.Question_0210.split(",");
        this.qa0210 = this.qa0210.map((s) => ({
          value: s == "Y" ? true : false,
        }));
        this.qa0210Other = map.Question_0210_Other;
        this.qa0210Health = map.Question_0210_Health;

        let tutorDate = map.TutorDate.split("-");
        this.tutorDate = `${tutorDate[0]} 年 ${tutorDate[1]} 月 ${
          tutorDate[2].split("T")[0]
        } 日`;

        this.tutorPlace = map.TutorPlace;

        // }
      } catch (e) {
        // const str = e + " API: " + url;
        // this.$root.$emit("dtc-server-error", str);
      }
    },
  },
  watch: {},
  async mounted() {
    this.getData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  width: 1150px;
  margin: 0 auto;
}
.dtc-header {
  border: 1px solid black;
  height: 80px;
  font-size: 24px;
  text-align: center;
}
.h2 {
  border: 1px solid black;
  border-top: none;
  height: 40px;
  font-size: 18px;
  text-align: left;
  line-height: 40px;
  padding-left: 5px;
  font-weight: bold;
  margin-bottom: 0;
}

.grid-4,
.grid-2 {
  display: grid;
  border: 1px solid black;
  min-height: 30px;
  border-top: none;
  grid-template-columns: 150px 300px 300px 400px;
  > div {
    border-right: 1px solid black;
    border-top: 1px solid black;
    height: 100%;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    min-height: 30px;
  }
  > div:last-child {
    border-right: none;
  }
}
.grid-2 {
  grid-template-columns: 220px 930px;
  .grid-2-left {
    border-right: 0px;
    text-align: left;
    padding-left: 40px;
  }
  .grid-2-right {
    text-align: left;
    padding-left: 0px;
    padding-bottom: 9px;
  }
}

footer {
  border: 1px solid black;
  border-top: none;
  min-height: 140px;
  font-size: 13px;
  text-align: left;
  padding-left: 2px;
  padding-top: 10px;
  &.footer-last {
    border: 0px;
    .footerWord {
      // border: 0px;
      font-size: 26px;
      margin-top: 30px;
      padding-left: 45px;
      .footerWordTime {
        display: inline-block;
        margin-left: 260px;
      }
    }
  }
}
.icon {
  font-size: 16px;
}
.dtc-title {
  display: grid;
  grid-template-columns: 450px 1fr;
}

@media print {
  @page {
    size: A4 landscape;
  }
  .print-border {
    border-top: 1px solid black;
  }
}
</style>
